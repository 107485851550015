(function (global){
﻿'use strict';

/* theme.js -- Components for theme / master template related items such as Main Navigation, Header interactions, and Footer interactions */

var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');

function _registerEventHandlers() {

    $(document).ready(function () {
        $(".flickrLink").each(function(){
            var href = $(this).attr("href");
            var parent = $(this).parent();
            $.ajax({
                url: '/umbraco/api/flickr/get',
                type: "get",
                data: { url: href },
                success: function(data) {
                    console.log('AJAX returned data.');
                    console.log(data);
                    parent.siblings(".loading-events").hide();
                },
                error: function(jqXHR, textStatus, responseText) {
                    console.log('AJAX error.');
                    console.log(jqXHR);
                    console.log(responseText);
                    parent.hide();
                    parent.siblings('.no-gallery-returned').show();
                    parent.siblings(".loading-events").hide();
                }
            });
        });
        
    });

}

_registerEventHandlers();

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})